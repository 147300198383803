@import url("@fontsource/archivo");

.header {
  overflow: hidden;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  gap: 25px;

  background: #004945;
  top: 0px;
  position: sticky;
}

.main-image {
  /* Frame 25 */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 4.55px;

  width: 40px;
  height: 36.82px;

  border-radius: 4.54545px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.largeSiteNav {
  /* Auto layout */
  font-family: "Archivo";
  color: #eefffa;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 25px;

  /* width: 510px; */
  /* height: 22px; */

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.smallSiteNav {
  display: none;
}

.largeSiteNav p {
  cursor: pointer;
}

.navLink {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: #eefffa;
  text-decoration: none;
}
.activeLink {
  color: #1fc086;
}

@media screen and (max-width: 685px) {
  .largeSiteNav {
    display: none;
  }

  .smallSiteNav {
    display: flex;
    size: 40px;
    color: #eefffa;
    cursor: pointer;
  }
}
