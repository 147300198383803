.backdrop {
  display: none;
  /* position: fixed;
  top: 0;
  left: 0;
  width: 0%;
  height: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75); */
}

.modal {
  position: fixed;
  top: 0vh;
  left: 0vh;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 20;
  backdrop-filter: blur(8px);
  overflow: hidden;

  /* padding: 1rem;
  padding-bottom: 0rem;
  border-radius: 6px;
  border-top-right-radius: 0px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30; */
  animation: slide-in 300ms ease-out forwards;
}

.crossIconContainer {
  position: fixed;
  top: 0rem;
  right: 0rem;
  height: 80px;
  width: 80px;
}

.crossIcon {
  position: fixed;
  top: 38px;
  right: 29px;
  width: 20px;
  height: 20px;
  color: #004945;
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textContent {
  display: flex;
  flex-direction: column;
  /* justify-items: center; */
  align-items: center;
  text-align: center;

  font-family: "Archivo";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 23px;

  color: #004945;
}

.textContent p {
  margin-top: 0px;
  margin-bottom: 30px;
  margin-right: 0px;
  cursor: pointer;
}

/* @media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
} */

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(3rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
