.contentSection {
  margin-top: 40px;
}
.contentRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.listNumber {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 40px;
  height: 40px;

  background: #004945;
  border-radius: 50px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}

.listText {
  margin-left: 13px;
  width: 70vw;
  text-align: left;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 32px;
  display: flex;
  align-items: center;

  color: #004945;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
}
