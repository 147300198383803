.benefitsSectionContainer {
  margin-top: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.benefitsSectionHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.headerText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  display: flex;
  align-items: flex-end;

  color: #eefffa;
  width: 70%;
}

.benefitsSectionListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-bottom: 20px;
}

.greenCheckMark {
  margin-right: 10px;
}

.benefitText {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #25e09d;
}
