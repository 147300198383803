@import url("@fontsource/archivo");
@import url("@fontsource/roboto");

body {
  overflow-y: scroll;

  margin: 0;
  font-family: "Archivo";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #004945;
}

code {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-family: "Archivo";
}
