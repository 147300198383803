.contentSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.image {
  width: 190px;
}

.contentRow {
  width: 750px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 50px;
  padding: 30px 35px;
  gap: 30px;

  background: #ffffff;
  border: 2.73px solid #004945;
  box-shadow: 1.65795px 1.65795px 0px #004945;
}
.listNumber {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 55px;
  height: 55px;

  background: #004945;
  border-radius: 60px;

  font-family: "Archivo";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;

  color: #ffffff;
}
.listText {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 34px;

  color: #004945;
}
