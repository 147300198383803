.contentSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}

.sectionHeader {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  cursor: pointer;
}

.sectionHeader h2 {
  cursor: pointer;
  text-align: left;
  padding: 0px;
  margin: 0px;
  line-height: 120%;
}

.shrunkenSection {
  /* height: 60px; */
  overflow: hidden;
  cursor: pointer;
  padding: 0px;
  /* margin: 0px; */
}
.fullSizeSection {
  height: auto;
  overflow: scroll;
  padding: 0px;
}

.sectionHeader .caret {
  font-size: 30px;
  cursor: pointer;
  margin-right: 30px;
}

/* .mobile {
  position: absolute;
  left: 10%;
}

.desktop {
  position: absolute;
  left: 15%;
} */
