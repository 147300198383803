.submitText,
.thankYouText {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;

  /* padding-top: 10px; */
  padding-bottom: 10px;

  color: #004945;

  font-family: "Archivo";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 23px;
}

/* .signupBox {
  width: 100%;
  color: #1fc086;
}

.signupBoxContent {
  max-width: 494px;
} */

@media screen and (min-width: 700px) {
  [class*="-control"] {
    width: 446.61px;
  }
}
@media screen and (max-width: 700px) {
  [class*="-control"] {
    width: 100%;
  }
}

@media screen and (min-width: 700px) {
  .signupBoxContent {
    display: flex;
    flex-direction: column;
    padding: 25px;
    gap: 10px;

    background: #eefffa;
    /* border: 2px solid #004945; */
  }

  input {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8.65084px 17.3017px;
    margin-bottom: 10px;

    gap: 17.3px;

    /* width: 95%; */
    width: 406.59px;
    height: 21px;

    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    color: #004945;

    opacity: 0.8;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    background: #ffffff;
    border: 1.73017px solid #004945;
  }

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;

    width: 100%;
    height: 38.3px;

    background: #1fc086;
    border: 1.73017px solid #004945;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;

    font-family: "Arial";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;

    align-items: center;

    color: #004945;

    margin-bottom: 10px;
  }
}

@media screen and (max-width: 700px) {
  .signupBoxContent {
    /* different line */
    margin-top: 20px;
    margin-bottom: 30px;
    max-width: 446px;

    /* text-align: left; */
    padding: 20px 25px 20px 25px;
    gap: 10px;

    background: #eefffa;
    /* border: 2px solid #004945; */
  }

  .submitText,
  .thankYouText {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: left;

    /* padding-top: 10px; */
    padding-bottom: 10px;

    color: #004945;

    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
  }

  form {
    /* position: relative;
    width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: left;
  }

  input {
    font-family: "Archivo";
    padding: 8px 16px;
    margin-bottom: 10px;

    /* width of just the text portion*/
    /* width: 80%; */
    height: 21px;

    font-family: "Arial";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;

    opacity: 0.8;

    background: #ffffff;
    color: #004945;
    border: 2px solid #004945;
  }

  button {
    height: 38.3px;
    /* really this should be a more dynamic width */
    /* width: 292.5px; */
    /* padding: 0%; */
    padding: 8px 16px;

    font-family: "Arial";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;

    color: #004945;
    background: #1fc086;
    border: 2px solid #004945;

    /* margin-bottom: 10px; */
  }
}

[class*="dropdownSelect"] {
  overflow-y: visible;
}

[class*="MenuList"] div:active {
  background-color: #00a57d;
}

[class*="MenuList"] div:hover {
  background-color: #00a57d;
}

/* there's something interesting around this not overwriting the hover state to white when selected"*/
/* transparent seems to fix */
[class*="-option"]:hover {
  background-color: "";
}

[class*="-option"]:hover:active {
  background-color: #00a57d;
}
