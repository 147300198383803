.logoAndHeadlineDiv {
  text-align: left;
  position: relative;
  /* width: 663.17px; */
  /* height: 196px; */
  /* left: 0px; */
  /* top: 15vh; */
}
.wordLogo {
  width: 241px;
  height: 101.06px;
  margin-bottom: 20px;
  /* left: 0px;
  top: 98px; */

  border-radius: 7.77419px;
}
.headline1 {
  /* Buy and sell shares in houses */
  font-family: "Archivo";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 35px;
  margin: 0px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: #eefffa;
}

.headline2 {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 23px;
  margin: 0px;

  display: flex;
  align-items: center;

  color: #1fc086;
}

@media screen and (max-width: 700px) {
  /* .logoAndHeadlineDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
  } */
  .headline1 {
    /* Buy and sell shares in houses */
    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 23px;
    margin: 0px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: #eefffa;
  }

  .headline2 {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    margin: 0px;
    /* height: 50px; */

    display: flex;
    align-items: center;

    color: #1fc086;
  }
}
