.topSection {
  overflow: hidden;
}
@media screen and (min-width: 700px) {
  .topSection {
    width: 100%;
    margin-bottom: 50px;

    background: #004945;
  }

  .topContentBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-top: 75px;
    margin-left: 8vw;
    margin-right: 8vw;
  }
}

@media screen and (max-width: 1350px) and (min-width: 1250px) {
  .topContentBox {
    position: relative;
    margin-left: 6vw;
    margin-right: 6vw;
  }
}

@media screen and (max-width: 1250px) and (min-width: 1150px) {
  .topContentBox {
    position: relative;
    margin-left: 4.5vw;
    margin-right: 4.5vw;
  }
}

@media screen and (max-width: 1150px) and (min-width: 1100px) {
  .topContentBox {
    position: relative;
    margin-left: 3.5vw;
    margin-right: 3.5vw;
  }
}

@media screen and (max-width: 1100px) and (min-width: 950px) {
  .topContentBox {
    position: relative;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
  }
}

@media screen and (max-width: 950px) and (min-width: 850px) {
  .topContentBox {
    position: relative;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
  }
}

@media screen and (max-width: 850px) and (min-width: 750px) {
  .topContentBox {
    position: relative;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
  }
}

@media screen and (max-width: 750px) and (min-width: 700px) {
  .topContentBox {
    position: relative;
    margin-left: 0.25vw;
    margin-right: 0.25vw;
  }
}

@media screen and (max-width: 700px) {
  .topSection {
    width: 100%;
    /* this is for the select dropdown*/
    overflow: visible;

    background: #004945;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .topContentBox {
    /* position: relative; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-top: 102px;
    margin-left: 25px;
    margin-right: 25px;

    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
  }
}
