.mobileHowItWorksContainer {
  background-color: #eefffa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.howItWorksHeadline {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-top: 30px;

  color: #004945;
}
