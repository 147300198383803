.footerBox {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 99px;

  background: #004945;

  /* Inside auto layout */

  flex: none;
  order: 4;
  align-self: stretch;
  flex-grow: 0;
}

.joinLinkAndIcon {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.rightsReservedText {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #eefffa;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.getInvolvedLink {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  text-decoration-line: underline;

  color: #1fc086;
}
