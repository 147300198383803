/* add hover state */
/* add a border */
/* change position to cursor */

.addressDiv {
  /* display: flex;
  flex-direction: column;
  align-items: flex-start; */
}
input {
  box-sizing: border-box;
  height: 41px;

  font-family: "Archivo";
  padding: 8px 16px;
  margin-bottom: 10px;

  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  opacity: 0.8;

  background: #eefffa;
  color: #004945;
  border: 2px solid #004945;
}

.showSuggestionsList {
}

.hideSuggestionsList {
}

.suggestionsList {
  box-sizing: border-box;

  background-color: #eefffa;
  margin: 0px;
  margin-top: -12px;
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #004945;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  position: absolute;
  z-index: 1;
  padding: 8px 16px;
  cursor: default;
  border-bottom: 2px solid #004945;
  border-left: 2px solid #004945;
  border-right: 2px solid #004945;
}

.suggestionListItem:hover {
  background-color: #00a57d;
  color: #eefffa;
}

/* [class*="dropdownSelect"] {
  overflow-y: visible;
} */

[class*="suggestionListItem"] div:active {
  background-color: #00a57d;
}

[class*="suggestionListItem"] div:hover {
  background-color: #00a57d;
}

/* there's something interesting around this not overwriting the hover state to white when selected"*/
/* transparent seems to fix */
[class*="suggestionListItem"]:hover {
  background-color: "";
}

[class*="suggestionListItem"]:hover:active {
  background-color: #00a57d;
}

@media screen and (min-width: 700px) {
  input {
    box-sizing: border-box;
    width: 406px;
  }

  .suggestionsList {
    width: 406px;
  }
}

@media screen and (max-width: 700px) {
  input {
    box-sizing: border-box;
    width: 100%;
  }

  .suggestionsList {
    max-width: 78.4%;
  }
}
