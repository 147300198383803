.faqPageContainer {
  background-color: #eefffa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  width: 100%;
}

.questionSection {
  width: 90%;
}

section {
  margin: 1rem auto;
  width: 80%;
  max-width: 1400px;
  text-align: center;
  background-color: #89fedb;
  padding: 2rem;
  border-radius: 12px;
  /* border-color: #004945;
  border-width: 1px; */
}
