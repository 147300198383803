.wordLogo {
  width: 241px;
  height: 101.06px;
  margin-bottom: 20px;
  /* left: 0px;
  top: 98px; */

  border-radius: 7.77419px;
}

.topSectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 13%;
}
